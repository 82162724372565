var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(((_vm.$route.meta.title) + " " + (_vm.loading ? '' : ("(" + _vm.total_rows + ")"))))}})]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),(_vm.login.role_id == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"teal","loading":_vm.btn_xls_ldg,"disabled":_vm.data.length == 0},on:{"click":function($event){$event.preventDefault();return _vm.downloadXls.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v(" mdi-table-arrow-down ")])],1)]}}],null,false,2107618042)},[_c('span',{domProps:{"textContent":_vm._s('Descargar tabla XLS')}})]):_vm._e(),_c('BtnCircle',{attrs:{"tLabel":"Concentradores","tPosition":"bottom","icon":"mdi-group","color":"teal darken-3","size":"x-small","to":{ name: 'proveedores.concentradores' }}}),_c('BtnCircle',{attrs:{"tLabel":"Tipos de prestación","tPosition":"bottom","icon":"mdi-checkerboard","color":"brown darken-3","size":"x-small","to":{ name: 'proveedores.tipos_prestaciones' }}}),(_vm.login.permissions.benefits.read)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"pink","dark":"","to":{ name: 'providers.search_benefits' }}},on),[_c('v-icon',[_vm._v(" mdi-magnify-expand ")])],1)]}}],null,false,1605962482)},[_c('span',{domProps:{"textContent":_vm._s('Buscar | Prestaciones')}})]):_vm._e(),_c('BtnCircle',{attrs:{"tLabel":"Coberturas","tPosition":"bottom","icon":"mdi-file-certificate","color":"purple darken-3","size":"x-small","to":{ name: 'proveedores.coberturas' }}}),_c('BtnCircle',{attrs:{"tLabel":"Tipos","tPosition":"bottom","icon":"mdi-clipboard-list","color":"info","size":"x-small","to":{ name: 'proveedores.tipos' }}}),(_vm.login.permissions.provider_groups.read)?_c('BtnCircle',{attrs:{"tLabel":"Redes","tPosition":"bottom","icon":"mdi-table-network","color":"warning","size":"x-small","to":{ name: 'redes.proveedores' }}}):_vm._e(),(_vm.login.permissions.providers.create)?_c('BtnCircle',{attrs:{"tLabel":"Crear","tPosition":"bottom","icon":"mdi-plus","color":"success","size":"x-small","to":{ name: (_vm.page_route + ".store") }}}):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('v-select',{attrs:{"label":"Estado","items":_vm.filters,"item-value":"id","item-text":function (v) { return v.name; },"dense":""},on:{"change":_vm.getTableData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.provider_brand",fn:function(ref){
var item = ref.item;
return [(!item.provider_brand)?_c('v-icon',{attrs:{"small":"","color":"orange"},domProps:{"textContent":_vm._s('mdi-alert')}}):_c('span',{domProps:{"textContent":_vm._s(item.provider_brand)}})]}},{key:"item.with_module",fn:function(ref){
var item = ref.item;
return [(item.with_module)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v(" mdi-crosshairs-gps ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-crosshairs-off ")])]}},{key:"item.observation",fn:function(ref){
var item = ref.item;
return [(item.observation)?_c('v-icon',{attrs:{"small":"","color":"orange"},domProps:{"textContent":_vm._s('mdi-alert')}}):_vm._e()]}},{key:"item.cs",fn:function(ref){
var item = ref.item;
return [(!item.cs)?_c('v-icon',{attrs:{"small":"","color":"orange"},domProps:{"textContent":_vm._s('mdi-alert')}}):_c('span',{domProps:{"textContent":_vm._s(item.cs)}})]}},{key:"item.revision",fn:function(ref){
var item = ref.item;
return [(item.revision)?_c('v-icon',{attrs:{"small":"","color":"success"},domProps:{"textContent":_vm._s('mdi-check')}}):_c('v-icon',{attrs:{"small":"","color":"orange"},domProps:{"textContent":_vm._s('mdi-alert')}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"primary","to":{
                    name: (_vm.page_route + ".view"),
                    params: { id: item.id },
                  }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-eye-outline')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})]),(_vm.login.permissions.benefits.read)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"warning","to":{
                    name: 'prestaciones',
                    params: { provider_id: item.id },
                  }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-file')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Lista de precios')}})]):_vm._e()]}},{key:"item.general",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('span',{domProps:{"textContent":_vm._s(item.name + '. ')}}),_vm._v(" "),_c('br'),_c('span',{domProps:{"textContent":_vm._s(item.trade_name)}})]}}],null,true)})]}}])})],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }